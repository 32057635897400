<template>
    <div class="app-container">
        <div class="filter-container">
            <el-radio-group v-model="listQuery.type" class="filter-item" size="small" @change="handleFilter">
                <el-radio-button :label="1">外卖</el-radio-button>
                <el-radio-button :label="2">商城</el-radio-button>
            </el-radio-group>
        </div>

        <div class="filter-container" style="display: flex;">
            <div style="margin-right:20px;">
                <div style="margin-bottom:10px;font-size:14px;color: #606266;">显示名称</div>

                <el-input v-model="formDailySpecialName.value" placeholder="请输入显示名称" style="width:200px;" size="small" />
            </div>

            <div style="margin-right:20px;">
                <div style="margin-bottom:10px;font-size:14px;color: #606266;">特价横幅<span style="color:red;">(推荐尺寸：375*180)</span></div>

                <upload-one v-model="formDailySpecialBanner.banner" style="width: 375px;height: 180px;"></upload-one>
            </div>

            <div style="margin-right:20px;align-self:flex-end;">
                <el-button type="primary" size="small" @click="handleDailySpecialInfoSave">保存</el-button>
            </div>
        </div>

        <div class="filter-container" style="margin-top: 20px;">
            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">新增</el-button>

            <el-date-picker v-model="listQuery.sale_date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择售卖日期" style="width: 200px;" class="filter-item" size="small" clearable></el-date-picker>

            <el-select v-model="listQuery.shop_id" placeholder="请选择店铺" style="width: 200px" class="filter-item" size="small" filterable clearable>
                <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                </el-option>
            </el-select>

            <el-input v-model="listQuery.goods_name" placeholder="请输入商品名称" style="width: 200px;" class="filter-item" size="small" clearable/>

            <el-select v-model="listQuery.is_on" placeholder="状态" style="width: 120px" class="filter-item" size="small" clearable>
                <el-option label="下架" :value="0" />
                <el-option label="正常" :value="1" />
            </el-select>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <div class="filter-container">
            <el-button-group>
                <el-button type="primary" size="small" @click="handleIsOn(1)" :disabled="ids.length <= 0">批量上架</el-button>
                <el-button type="warning" size="small" @click="handleIsOn(0)" :disabled="ids.length <= 0">批量下架</el-button>
                <el-button type="danger" size="small" @click="handleDel(null)" :disabled="ids.length <= 0">批量删除</el-button>
            </el-button-group>
        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column type="selection" align="center" width="60"></el-table-column>

            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="学校" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.school?scope.row.school.school_name:'' }}
                </template>
            </el-table-column>

            <el-table-column label="类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type === 1" type="primary">外卖</el-tag>
                    <el-tag v-else-if="scope.row.type === 2" type="warning">商城</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop.shop_name }}
                </template>
            </el-table-column>

            <el-table-column label="商品名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.goods.goods_name }}
                </template>
            </el-table-column>

            <el-table-column label="封面" min-width="110" align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.goods.goods_thumb" class="user-avatar">
                </template>
            </el-table-column>

            <el-table-column label="售价" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sell_price }}
                </template>
            </el-table-column>

            <el-table-column label="权重" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.weight }}
                </template>
            </el-table-column>

            <el-table-column label="状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_on === 1" type="success">正常</el-tag>
                    <el-tag v-else-if="scope.row.is_on === 0" type="danger">下架</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>

                    <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>

                    <el-button type="primary" size="mini" @click="handleInfo(scope.row)">商品详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <el-dialog :visible.sync="dialogVisible">
            <el-form ref="formInfo" :model="formInfo" label-position="left" label-width="90px">
                <el-form-item label="商品名称">
                    <el-input v-model="formInfo.goods_name" placeholder="请输入商品名称" />
                </el-form-item>
                <el-form-item label="商品描述">
                    <el-input v-model="formInfo.goods_description" placeholder="请输入商品描述" />
                </el-form-item>
                <el-form-item label="封面">
                    <upload-one v-model="formInfo.goods_thumb"></upload-one>
                    <div style="font-size:12px;color:red;">*推荐尺寸：160*160</div>
                </el-form-item>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="商品价格">
                            <el-input v-model="formInfo.sell_price" type="number" min="0" placeholder="请输入商品价格" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="包装费">
                            <el-input v-model="formInfo.pack_fee" type="number" min="0" placeholder="请输入包装费" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="库存">
                            <el-input v-model="formInfo.goods_number" type="number" min="0" placeholder="请输入库存" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="权重">
                            <el-input v-model="formInfo.weight" type="number" min="0" placeholder="请输入库存" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="规格">
                    <el-table size="mini" :data="formInfo.specs" border fit>
                        <el-table-column label="名称" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.spec_name" />
                            </template>
                        </el-table-column>
                        <el-table-column label="价格(元)" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.spec_price" type="number" min="0" />
                            </template>
                        </el-table-column>
                        <el-table-column label="库存" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.spec_num" type="number" min="0" />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="属性">
                    <el-table size="mini" :data="formInfo.attrs" border fit>
                        <el-table-column label="名称" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.attr_name" />
                            </template>
                        </el-table-column>
                        <el-table-column label="值" align="center" min-width="240">
                            <template slot-scope="scope">
                                <div :key="index" v-for="(item,index) in scope.row.attr_val" style="margin-bottom: 10px;">
                                    <el-input style="display: inline-block;width: 150px;margin-right: 10px;" size="mini" v-model="item.name" placeholder="属性值名称" />
                                    <el-input style="display: inline-block;width: 150px;margin-right: 10px;" size="mini" v-model="item.price" placeholder="属性值价格" @input="item.price=item.price.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="70%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="120px">
                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="form.type" disabled>
                        <el-radio-button :label="1">外卖</el-radio-button>
                        <el-radio-button :label="2">商城</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="售价" prop="sell_price">
                    <el-input-number v-model="form.sell_price" :min="0" :precision="2" style="width: 100%;max-width: 220px;"></el-input-number>
                </el-form-item>

                <el-form-item label="权重" prop="weight">
                    <el-input-number v-model="form.weight" :min="0" :precision="0" style="width: 100%;max-width: 220px;"></el-input-number>
                    <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
                        <div>*权重越大，排名越前</div>
                    </div>
                </el-form-item>

                <el-form-item label="状态" prop="is_on">
                    <el-switch v-model="form.is_on" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>

                <el-form-item label="商品售卖时间" prop="goods_sell_time">
                    <el-table :data="form.goods_sell_time" border fit highlight-current-row style="margin-top: 15px">
                        <el-table-column label="售卖日期" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-date-picker size="small" v-model="scope.row.sale_date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 100%;max-width: 150px;" :disabled="scope.row.id > 0"></el-date-picker>
                            </template>
                        </el-table-column>

                        <el-table-column label="总库存量" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-input-number size="small" v-model="scope.row.total_stock" :min="0" :precision="0" style="width: 100%;max-width: 150px;"></el-input-number>
                            </template>
                        </el-table-column>

                        <el-table-column label="当前库存" min-width="110" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.current_stock }}
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" type="primary" @click="addGoodsSaleTime(scope.$index)">添加</el-button>

                                <el-button size="small" type="danger" @click="delGoodsSaleTime(scope.$index)" v-if="form.goods_sell_time.length > 1">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    type: 1,
                    sale_date: '',
                    shop_id: '',
                    goods_name: '',
                    is_on: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    type: '',
                    shop_id: '',
                    goods_id: '',
                    sell_price: '',
                    weight: 0,
                    is_on: 1,
                    goods_sell_time: [
                        {
                            id: '',
                            daily_special_goods_id: '',
                            sale_date: '',
                            total_stock: 0,
                            current_stock: 0,
                        }
                    ],
                },
                rules: {
                    type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                    sell_price: [{ required: true, message: '售价不能为空', trigger: 'change' }],
                    weight: [{ required: true, message: '权重不能为空', trigger: 'change' }],
                    is_on: [{ required: true, message: '请选择状态', trigger: 'change' }],
                },
                shops: [],
                ids: [],
                // 商品详情
                dialogVisible: false,
                formInfo: {
                    id: "",
                    school_id: "",
                    shop_id: "",
                    category_id: "",
                    goods_name: "",
                    goods_description: "",
                    goods_thumb: "",
                    sell_price: 0,
                    pack_fee: 0,
                    weight: 0,
                    goods_number: 0,
                    content: "",
                    banners: [],
                    specs: [],
                    attrs: [],
                    is_require: 0,
                },
                formDailySpecialBanner: {
                    id: '',
                    school_id: '',
                    type: '',
                    banner: '',
                },
                formDailySpecialName: {
                    id: '',
                    school_id: '',
                    key: '',
                    value: '',
                    remark: '',
                },
            };
        },
        created() {
            this.getList();
            this.getShopList();
            this.getDailySpecialName();
            this.getDailySpecialBanner();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            "listQuery.type": {
                handler(newValue, oldValue) {
                    this.getShopList();
                    this.getDailySpecialName();
                    this.getDailySpecialBanner();
                },
                deep: true
            },
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getShopList();
                this.getDailySpecialName();
                this.getDailySpecialBanner();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/dailySpecialGoods/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getShopList() {
                request({
                    url: "/api/backend/shop/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                        shop_type: this.listQuery.type,
                    }
                }).then(response => {
                    this.shops = response.data.data;
                });
            },
            getDailySpecialName() {
                let key = this.listQuery.type === 1 ? 'takeawayDailySpecialName' : 'shopDailySpecialName';
                let remark = this.listQuery.type === 1 ? '外卖天天特价名称' : '商城天天特价名称';
                request({
                    url: "/api/backend/keyValue/info",
                    method: "get",
                    params: {
                        school_id: this.school_id,
                        key: key,
                    }
                }).then(response => {
                    if (response.data) {
                        this.formDailySpecialName = response.data;
                    } else {
                        this.formDailySpecialName = {
                            id: '',
                            school_id: this.school_id,
                            key: key,
                            value: '天天特价',
                            remark: remark,
                        }
                    }
                });
            },
            getDailySpecialBanner() {
                request({
                    url: "/api/backend/dailySpecialBanner/info",
                    method: "get",
                    params: {
                        school_id: this.school_id,
                        type: this.listQuery.type,
                    }
                }).then(response => {
                    if (response.data) {
                        this.formDailySpecialBanner = response.data;
                    } else {
                        this.formDailySpecialBanner = {
                            id: '',
                            school_id: this.school_id,
                            type: this.listQuery.type,
                            banner: '',
                        }
                    }
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    type: this.listQuery.type || '',
                    shop_id: '',
                    goods_id: '',
                    sell_price: '',
                    weight: 0,
                    is_on: 1,
                    goods_sell_time: [
                        {
                            id: '',
                            daily_special_goods_id: '',
                            sale_date: '',
                            total_stock: 0,
                            current_stock: 0,
                        }
                    ],
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.$router.push(`/advert/addDailySpecialGoods?type=${this.listQuery.type}`)
            },
            handleUpdate(row) {
                this.resetForm()
                // this.form = Object.assign({}, row)
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                if (!this.form.shop_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择店铺"
                    });
                    return;
                }

                if (!this.form.goods_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择商品"
                    });
                    return;
                }

                if (this.form.goods_sell_time.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "商品售卖时间不能为空",
                    });
                    return;
                }
                for (let index = 0; index < this.form.goods_sell_time.length; index++) {
                    const element = this.form.goods_sell_time[index];

                    if (element.sale_date === '') {
                        this.$message({
                            type: "warning",
                            message: "售卖日期不能为空"
                        });
                        return;
                    }

                    if (element.total_stock === undefined) {
                        this.$message({
                            type: "warning",
                            message: "总库存量不能为空"
                        });
                        return;
                    }
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/dailySpecialGoods/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            handleSelectionChange(val) {
                let ids = [];
                val.forEach(element => {
                    ids.push(element.id);
                });
                this.ids = ids;
            },
            handleIsOn(is_on) {
                let text = is_on === 1 ? '上架' : '下架';
                this.$confirm('确定要' + text + '选中的选项吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/dailySpecialGoods/isOn',
                        method: 'post',
                        data: {
                            ids: this.ids,
                            is_on: is_on
                        }
                    }).then(() => {
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    });
                });
            },
            handleDel(item) {
                this.$confirm('确定要删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/dailySpecialGoods/del',
                        method: 'post',
                        data: {
                            ids: item ? [item.id] : this.ids
                        }
                    }).then(() => {
                        // 删除最后一条数据时无数据问题
                        this.list.length <= 1 ? this.listQuery.page-- : false;
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    });
                }).catch(() => {

                });
            },
            // 添加商品售卖时间
            addGoodsSaleTime(index) {
                let obj = {
                    id: '',
                    daily_special_goods_id: '',
                    sale_date: '',
                    total_stock: 0,
                    current_stock: 0,
                };

                this.form.goods_sell_time.splice(index+1, 0, obj)
            },
            // 删除商品售卖时间
            delGoodsSaleTime(index) {
                this.form.goods_sell_time.splice(index, 1)
            },
            // 商品详情
            handleInfo(item) {
                item = item.goods;
                this.formInfo = {
                    id: item.id,
                    school_id: item.school_id,
                    shop_id: item.shop_id,
                    category_id: item.category_id,
                    goods_name: item.goods_name,
                    goods_description: item.goods_description,
                    goods_thumb: item.goods_thumb,
                    sell_price: item.sell_price,
                    pack_fee: item.pack_fee,
                    weight: item.weight,
                    goods_number: item.goods_number,
                    content: item.content,
                    banners: item.banners,
                    specs: JSON.parse(JSON.stringify(item.specs)),
                    attrs: JSON.parse(JSON.stringify(item.attrs)),
                    is_require: item.is_require,
                };
                this.dialogVisible = true;
            },
            // 天天特价信息
            handleDailySpecialInfoSave() {
                if (!this.formDailySpecialName.value) {
                    this.$message({
                        type: "warning",
                        message: "请输入显示名称"
                    });
                    return;
                }

                if (!this.formDailySpecialBanner.banner) {
                    this.$message({
                        type: "warning",
                        message: "请选择特价横幅"
                    });
                    return;
                }

                this.$confirm("确定要保存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    // 显示名称
                    await request({
                        url: "/api/backend/keyValue/save",
                        method: "post",
                        data: this.formDailySpecialName
                    });

                    this.getDailySpecialName();

                    // 特价横幅
                    await request({
                        url: "/api/backend/dailySpecialBanner/save",
                        method: "post",
                        data: this.formDailySpecialBanner
                    });

                    this.getDailySpecialBanner();

                    this.$message({
                        type: "success",
                        message: "保存成功!"
                    });
                }).catch(() => {

                });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
